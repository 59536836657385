import escapeHTML from 'escape-html';
import Image from 'next/image';
import type { ReactElement } from 'react';
import { Fragment } from 'react';
import { Text } from 'slate';

interface NodeProps {
  bold: string;
  italic: string;
  underline: string;
  type: string;
  url: string;
  children: NodeProps[];
  newTab: boolean;
  strikethrough: string;
  text?: string;
  value?: {
    sizes: {
      free: {
        filename: string;
        width: number;
        height: number;
        url: string;
      };
    };
  };
}

const Serialize = (children: any): ReactElement[] | null => {
  return children.map((node: NodeProps, i: number) => {
    if (Text.isText(node)) {
      let text = (
        <span dangerouslySetInnerHTML={{ __html: escapeHTML(node.text) }} />
      );

      if (node.bold) {
        text = <strong key={i}>{text}</strong>;
      }

      if (node.italic) {
        text = <em key={i}>{text}</em>;
      }

      if (node.strikethrough) {
        text = (
          <span className="line-through" key={i}>
            {text}
          </span>
        );
      }

      if (node.underline) {
        text = (
          <span className="underline" key={i}>
            {text}
          </span>
        );
      }

      return <Fragment key={i}>{text}</Fragment>;
    }

    if (!node) {
      return null;
    }

    switch (node.type) {
      case 'h1':
        return <h1 key={i}>{Serialize(node.children)}</h1>;
      case 'h2':
        return <h2 key={i}>{Serialize(node.children)}</h2>;
      case 'h3':
        return <h3 key={i}>{Serialize(node.children)}</h3>;
      case 'h4':
        return <h4 key={i}>{Serialize(node.children)}</h4>;
      case 'ul':
        return <ul key={i}>{Serialize(node.children)}</ul>;
      case 'ol':
        return <ol key={i}>{Serialize(node.children)}</ol>;
      case 'li':
        return <li key={i}>{Serialize(node.children)}</li>;
      case 'link':
        if (node.newTab) {
          return (
            <a
              target="_blank"
              rel="noreferrer"
              href={escapeHTML(node.url)}
              key={i}
            >
              {Serialize(node.children)}
            </a>
          );
        }
        return (
          <a href={escapeHTML(node.url)} key={i}>
            {Serialize(node.children)}
          </a>
        );
      case 'upload':
        return (
          <Image
            src={node.value?.sizes.free.url ?? ''}
            width={node.value?.sizes.free.width ?? 0}
            height={node.value?.sizes.free.height ?? 0}
            alt=""
          />
        );

      default:
        // If empty text node is the only child, return new line
        if (node.children.length === 1 && node?.children?.[0]?.text === '') {
          return <br />;
        }

        return <p key={i}>{Serialize(node.children)}</p>;
    }
  });
};
export default Serialize;
